import Vue from 'vue'
import VueRouter from 'vue-router'
import { defineAsyncComponent } from 'vue'
// import HomeView from '../views/HomeView.vue'
// import AboutView from '../views/AboutView.vue'
// import ArticlesVente from '../views/VentesAction/VentesAction.vue'
// import ArticlesList from '../views/ArticlesList/ArticlesList.vue'
// import ArticlesCategorie from '../views/ArticlesCategorie/ArticlesCategorie.vue'
// const ArticlesList = () => import('../views/ArticlesList/ArticlesList.vue');
const ArticlesList = defineAsyncComponent(() => import('../views/ArticlesList/ArticlesList.vue'))

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'AboutView',
  //   component: AboutView
  // },
  {
    path: '/',
    name: 'ArticlesList',
    component: ArticlesList
  },
  {
    path: '/:catId',
    name: 'ArticlesListCat',
    component: ArticlesList
  },
  // {
  //   path: '/categorie',
  //   name: 'ArticlesCategorie',
  //   component: ArticlesCategorie
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
